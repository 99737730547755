import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link,useNavigate } from 'react-router-dom'
import './homemain.css'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProducts } from '../../features/products/productSlice';
import Product from '../../components/Product'
import Reviews from './Reviews';
import Carousel1 from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const HomeMain = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const bannerState=useSelector((state)=>state?.banner?.banner)
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 650 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };
  const collections = ["Men's Premium T Shirts", "Women's Dresses", "Men's Belt","Kid's T-Shirts"];
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const page = 1;
  const limit = 10;
  const productStat = useSelector((state) => state?.product);

  const { isError, isLoading, isSuccess } = productStat
  const sort = "-createdAt"
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const promises = collections.map((collectionName) => dispatch(getAllProducts({ limit, collectionName, page, sort })));

        const results = await Promise.all(promises);
        
        // Logging results to check the structure

        const combinedPayArray = results.reduce((accumulator, currentObject) => {
          // Check if payload exists and is an array
          if (Array.isArray(currentObject.payload.products)) {
            return [...accumulator, ...currentObject.payload.products];
          } else {
            console.warn('Payload is not an array:', currentObject.payload);
            return accumulator;
          }
        }, []);

        setData(combinedPayArray);
      } catch (error) {
        console.error('Failed to fetch products:', error);
      }
    };

    fetchProducts();
  }, [dispatch, limit, page, sort]);
  const shirts = data.filter(object => object.collectionName && object.collectionName === "Men's Premium T Shirts").slice(0, 4);
  const tshirt = data.filter(object => object.collectionName && object.collectionName === "Women's Dresses").slice(0, 4);
  const jeans = data.filter(object => object.collectionName && object.collectionName === "Men's Belt").slice(0, 4);
  const kids = data.filter(object => object.collectionName && object.collectionName === "Kid's T-Shirts").slice(0, 4);



  useEffect(() => {
    if (isLoading && data) {
      setLoading(true)
    }
    if (isSuccess && data) {
      setLoading(false)
    }
  }, [isLoading, isSuccess])

  const collectionState=useSelector((state)=>state?.collection?.collection)
  const modifyCloudinaryUrl = (url) => {
    const urlParts = url?.split('/upload/');
    return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
  };
  return (
    <div className='homeMain'>
      <div className="hero-section">
      <Carousel activeIndex={index} onSelect={handleSelect} fade controls={false}>
      <Carousel.Item interval={2500}>
        <img src={modifyCloudinaryUrl(bannerState[0]?.images[0]?.url)} alt=""/>
      </Carousel.Item>
      <Carousel.Item interval={2500}>
        <img src={modifyCloudinaryUrl(bannerState[38]?.images[0]?.url)} alt="" />
      </Carousel.Item>
      <Carousel.Item interval={2500}>
        <img src={modifyCloudinaryUrl(bannerState[39]?.images[0]?.url)} alt="" />
      </Carousel.Item>
      
    </Carousel>
      </div>
      <div className="categories">
          <Link to="/men">
            <div className="cate">
              <img src={modifyCloudinaryUrl(bannerState[1]?.images[0]?.url)} alt={bannerState[1]?.alt} />
            </div>
          </Link><Link to="/women">
            <div className="cate">
              <img src={modifyCloudinaryUrl(bannerState[2]?.images[0]?.url)} alt={bannerState[2]?.alt} />
            </div>
          </Link><Link to="/kids">
            <div className="cate">
              <img src={modifyCloudinaryUrl(bannerState[3]?.images[0]?.url)} alt={bannerState[3]?.alt} />
            </div>
          </Link><Link to="/accessories">
            <div className="cate">
              <img src={modifyCloudinaryUrl(bannerState[4]?.images[0]?.url)} alt={bannerState[4]?.alt} />
            </div>
          </Link>

      </div>

      <div className="margin-sec">

      <div className='col-slider'>
      <p className='section-heading'>Premium Men's Collection</p>
      <div className="c-sec">
        {
          collectionState && <Carousel1
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={false}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={600}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
        <Link to={`/collections/${collectionState[2]?.handle}`} >
        <div className="collection-card">
            
            <img src={modifyCloudinaryUrl(collectionState[2]?.images[0]?.url)} alt="" className='col-img'/>
        </div>
        </Link>
        <Link to={`/collections/${collectionState[3]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[3]?.images[0]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[43]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[43]?.images[0]?.url)} alt="" />
        </div>
        </Link>
       
        <Link to={`/collections/${collectionState[0]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[0]?.images[0]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[6]?.handle}`} >

        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[6]?.images[0]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[5]?.handle}`} >

        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[5]?.images[0]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[1]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[1]?.images[0]?.url)} alt="" />
        </div>
        </Link>
        
        <Link to={`/collections/${collectionState[10]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[10]?.images[0]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[8]?.handle}`}>
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[8]?.images[0]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[17]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[17]?.images[0]?.url)} alt="" />
        </div>
        </Link>
       
        <Link to={`/collections/${collectionState[4]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[4]?.images[0]?.url)} alt="" />
        </div>
        </Link>


        <Link to={`/collections/${collectionState[20]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[20]?.images[0]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[7]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[7]?.images[0]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[11]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[11]?.images[0]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[19]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[19]?.images[0]?.url)} alt="" />
        </div>
        </Link>
        </Carousel1>
        }
      
        
       
      </div>
    </div>


        <div className="products-listing">
          <p className="section-heading">Men's Featured Products</p>


          {
            loading ? <div className="skeleton">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
              :
              <div className="product-list pl1">


{
                  shirts?.map((arm, index) => {

                    return <Product key={index} keys={index} id={arm?._id} img={arm?.images} title={arm?.title} price={arm?.price} variants={arm?.variants} handle={arm?.handle} prdt={arm} alt={arm?.alt}/>


                  })
                }

               

              </div>
          }




        </div>
        <div className="trending-collections">
          <p className="section-heading">Men's Trending</p>
          <div className="trending-collection">
            <Link to="/collections/men-premium-shirt">
              <div className="trending-card">
                <img src={bannerState[11]?.images[0]?.url || "https://res.cloudinary.com/dqh6bd766/image/upload/v1710341072/a28_ywebvi.jpg"} alt={bannerState[11]?.alt} />
              </div>
            </Link><Link to="/collections/t-shirts">
              <div className="trending-card">
                <img src={bannerState[12]?.images[0]?.url || "https://res.cloudinary.com/dqh6bd766/image/upload/v1710341072/a29_e50fly.jpg"} alt={bannerState[12]?.alt} />
              </div>
            </Link><Link to="/collections/mens-denim-jeans">
              <div className="trending-card">
                <img src={bannerState[13]?.images[0]?.url || "https://res.cloudinary.com/dqh6bd766/image/upload/v1710341077/a27_juuuzb.jpg"} alt={bannerState[13]?.alt} />
              </div>
            </Link>
          </div>
        </div>
        <div className='col-slider'>
      <p className='section-heading'>Premium Women's Collection</p>
      <div className="c-sec">
        {
          collectionState && <Carousel1
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={false}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={600}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
        <Link to={`/collections/${collectionState[18]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[18]?.images[0]?.url)} alt="" />
                </div></Link>
                <Link to={`/collections/${collectionState[22]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[22]?.images[0]?.url)} alt="" />
                </div></Link>
                <Link to={`/collections/${collectionState[12]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[12]?.images[0]?.url)} alt="" />
                </div></Link>
                <Link to={`/collections/${collectionState[33]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[33]?.images[0]?.url)} alt="" />
                </div></Link>
                <Link to={`/collections/${collectionState[23]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[23]?.images[0]?.url)} alt="" />
                </div></Link>
                <Link to={`/collections/${collectionState[35]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[35]?.images[0]?.url)} alt="" />
                </div></Link>
                <Link to={`/collections/${collectionState[38]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[38]?.images[0]?.url)} alt="" />
                </div></Link>
                
                <Link to={`/collections/${collectionState[39]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[39]?.images[0]?.url)} alt="" />
                </div></Link>
                <Link to={`/collections/${collectionState[41]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[41]?.images[0]?.url)} alt="" />
                </div></Link><Link to={`/collections/${collectionState[28]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[28]?.images[0]?.url)} alt="" />
                </div></Link><Link to={`/collections/${collectionState[30]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[30]?.images[0]?.url)} alt="" />
                </div></Link>
        </Carousel1>
        }
      
        
       
      </div>
    </div>
      
        <div className="products-listing">
          <p className="section-heading">Women's Featured Products</p>

          {
            loading ? <div className="skeleton">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
              :
              <div className="product-list">
                {

                  tshirt.map((arm, index) => {

                    return <Product key={index} id={arm?._id} img={arm?.images} title={arm?.title} price={arm?.price} variants={arm?.variants} handle={arm?.handle} prdt={arm} alt={arm?.alt}/>


                  })
                }

              </div>
          }


        </div>
        <div className="trending-collections">
          <p className="section-heading">Women's Trending</p>
          <div className="trending-collection">
            <Link to="/collections/womens-dress">
              <div className="trending-card">
                <img src={bannerState[20]?.images[0]?.url || "https://res.cloudinary.com/dqh6bd766/image/upload/v1710341073/a31_kv2mnb.jpg"} alt={bannerState[20]?.alt} />
              </div>
            </Link><Link to="/collections/womens-shirt-t-shirts">
              <div className="trending-card">
                <img src={bannerState[21]?.images[0]?.url || "https://res.cloudinary.com/dqh6bd766/image/upload/v1710341073/a30_g0wu0m.jpg"} alt={bannerState[21]?.alt} />
              </div>
            </Link>
            <Link to="/collections/womens-co-ord-set">
              <div className="trending-card">
                <img src={bannerState[22]?.images[0]?.url || "https://res.cloudinary.com/dqh6bd766/image/upload/v1710341072/a32_l5be5c.jpg"} alt={bannerState[22]?.alt} />
              </div>
            </Link>
          </div>
        </div>

        <div className='col-slider'>
      <p className='section-heading'>Premium Junior Collection</p>
      <div className="c-sec">
        {
          collectionState && <Carousel1
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={false}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={600}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
        <Link to={`/collections/${collectionState[16]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[16]?.images[0]?.url)} alt="" />
      </div></Link>
      <Link to={`/collections/${collectionState[13]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[13]?.images[0]?.url) } alt="" />
      </div></Link>
      <Link to={`/collections/${collectionState[36]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[36]?.images[0]?.url)} alt="" />
      </div></Link>
      
      <Link to={`/collections/${collectionState[24]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[24]?.images[0]?.url)} alt="" />
      </div></Link>
      <Link to={`/collections/${collectionState[34]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[34]?.images[0]?.url)} alt="" />
      </div></Link>
      <Link to={`/collections/${collectionState[21]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[21]?.images[0]?.url)} alt="" />
      </div></Link>
<Link to={`/collections/${collectionState[29]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[29]?.images[0]?.url)} alt="" />
      </div></Link>
        </Carousel1>
        }
      
        
       
      </div>
    </div>
       
        <div className="products-listing">
          <p className="section-heading">Kid's Featured Products</p>


          {
            loading ? <div className="skeleton">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
              :
              <div className="product-list pl1">


{
                  kids.map((arm, index) => {

                    return <Product key={index} keys={index} id={arm?._id} img={arm?.images} title={arm?.title} price={arm?.price} variants={arm?.variants} handle={arm?.handle} prdt={arm} alt={arm?.alt}/>


                  })
                }

               

              </div>
          }




        </div>


        <div className='col-slider'>
      <p className='section-heading'>Premium Accessories Collection</p>
      <div className="c-sec">
        {
          collectionState && <Carousel1
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={false}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={600}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
        <Link to={`/collections/${collectionState[49]?.handle}`}>
      <div className="collection-card">
          <img src={collectionState && modifyCloudinaryUrl(collectionState[49]?.images[0]?.url)} alt="" />
      </div>
      </Link>
      <Link to={`/collections/${collectionState[14]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[14]?.images[0]?.url)} alt="" />
      </div>
      </Link>
      <Link to={`/collections/${collectionState[25]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[25]?.images[0]?.url)} alt="" />
      </div></Link><Link to={`/collections/${collectionState[15]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[15]?.images[0]?.url)} alt="" />
      </div></Link><Link to={`/collections/${collectionState[46]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[46]?.images[0]?.url)} alt="" />
      </div></Link><Link to={`/collections/${collectionState[45]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[45]?.images[0]?.url)} alt="" />
      </div></Link><Link to={`/collections/${collectionState[44]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[44]?.images[0]?.url)} alt="" />
      </div></Link><Link to={`/collections/${collectionState[47]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[47]?.images[0]?.url)} alt="" />
      </div></Link><Link to={`/collections/${collectionState[48]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[48]?.images[0]?.url)} alt="" />
      </div>
      </Link>
        </Carousel1>
        }
      
        
       
      </div>
    </div>


        <div className="products-listing">
          <p className="section-heading">Featured Accessories</p>
          {
            loading ? <div className="skeleton">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
              :
              <div className="product-list">
                {

                  jeans.map((arm, index) => {

                    return <Product key={index} id={arm?._id} img={arm?.images} title={arm?.title} price={arm?.price} variants={arm?.variants} handle={arm?.handle} prdt={arm} alt={arm?.alt}/>


                  })
                }

              </div>
          }



        </div>

        <div className="ratings">
          <Reviews/>
        </div>
        

<p style={{textAlign:'center',fontSize:'30px',fontWeight:500}}>Secure With Voguemine</p>

        <div className="icon-footer">
          <div>
          <img src={modifyCloudinaryUrl("https://res.cloudinary.com/dqh6bd766/image/upload/v1712582661/FREE_FAST_SHIPPING_IN_INDIA_qug5zj.png")} alt="" />
<p>Fast Shipping</p>
          </div>
          <div>
          <img src={modifyCloudinaryUrl("https://res.cloudinary.com/dqh6bd766/image/upload/v1712582661/SECURE_PAYMENT_eblv7s.png")} alt="" />
<p>Secure Payment</p>
          </div>
          <div>
          <img src={modifyCloudinaryUrl("https://res.cloudinary.com/dqh6bd766/image/upload/v1712582665/Easy_Exchange_gfloux.png")} alt="" />

<p>Easy Exchange</p>
          </div>
        </div>




      </div>

    </div>
  )
}

export default HomeMain
