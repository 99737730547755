import React, { useEffect, useState } from 'react'
import './category.css'
import {Link} from 'react-router-dom'
import {useSelector } from 'react-redux'
import img from '../../images/col.jpg'
import loadingImg from '../../images/loading.gif'
const Men = () => {
    const collectionState=useSelector((state)=>state?.collection?.collection)
    const modifyCloudinaryUrl = (url) => {
        const urlParts = url?.split('/upload/');
        return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
      };
      const [loading,setLoading]=useState(true)
      useEffect(()=>{
setTimeout(()=>{
    setLoading(false)
},500)
      },[collectionState])
  return (
    <div className='categoryPage'>
        <div className="loading" style={{ display: loading === true ? "block" : "none" }}>
        <div className="loader">
          <img src={loadingImg} alt="" />
          <p>Loading . . .</p>
        </div>
      </div>
        <p className="category-heading">Men's Collection</p>
      <div className="collections">
      <Link to={`/collections/${collectionState[2]?.handle}`} >
        <div className="collection-card">
            
            <img src={modifyCloudinaryUrl(collectionState[2]?.images[2]?.url)} alt="" className='col-img'/>
        </div>
        </Link>
        <Link to={`/collections/${collectionState[3]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[3]?.images[2]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[43]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[43]?.images[2]?.url)} alt="" />
        </div>
        </Link>
       
        <Link to={`/collections/${collectionState[0]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[0]?.images[2]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[6]?.handle}`} >

        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[6]?.images[2]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[5]?.handle}`} >

        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[5]?.images[2]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[1]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[1]?.images[2]?.url)} alt="" />
        </div>
        </Link>
        
        <Link to={`/collections/${collectionState[10]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[10]?.images[2]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[8]?.handle}`}>
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[8]?.images[2]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[17]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[17]?.images[2]?.url)} alt="" />
        </div>
        </Link>
       
        <Link to={`/collections/${collectionState[4]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[4]?.images[2]?.url)} alt="" />
        </div>
        </Link>


        <Link to={`/collections/${collectionState[20]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[20]?.images[2]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[7]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[7]?.images[2]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[11]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[11]?.images[2]?.url)} alt="" />
        </div>
        </Link>
        <Link to={`/collections/${collectionState[19]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[19]?.images[2]?.url)} alt="" />
        </div>
        </Link>
      </div>
    </div>
  )
}

export default Men
