import React,{useEffect,useState} from 'react'
import './category.css'
import { Link } from 'react-router-dom'
import {useSelector } from 'react-redux'
import loadingImg from '../../images/loading.gif'
const Women = () => {
    const collectionState=useSelector((state)=>state?.collection?.collection)
    const modifyCloudinaryUrl = (url) => {
        const urlParts = url?.split('/upload/');
        return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
      };
      const [loading,setLoading]=useState(true)
      useEffect(()=>{
setTimeout(()=>{
    setLoading(false)
},500)
      },[collectionState])
    return (
        <div className='categoryPage'>
            <div className="loading" style={{ display: loading === true ? "block" : "none" }}>
        <div className="loader">
          <img src={loadingImg} alt="" />
          <p>Loading . . .</p>
        </div>
      </div>
                   <p className="category-heading">Women's Collection</p>

            <div className="collections">
            <Link to={`/collections/${collectionState[18]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[18]?.images[2]?.url)} alt="" />
                </div></Link>
                <Link to={`/collections/${collectionState[22]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[22]?.images[2]?.url)} alt="" />
                </div></Link>
                <Link to={`/collections/${collectionState[12]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[12]?.images[2]?.url)} alt="" />
                </div></Link>
                <Link to={`/collections/${collectionState[33]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[33]?.images[2]?.url)} alt="" />
                </div></Link>
                <Link to={`/collections/${collectionState[23]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[23]?.images[2]?.url)} alt="" />
                </div></Link>
                <Link to={`/collections/${collectionState[35]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[35]?.images[2]?.url)} alt="" />
                </div></Link>
                <Link to={`/collections/${collectionState[38]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[38]?.images[2]?.url)} alt="" />
                </div></Link>
                
                <Link to={`/collections/${collectionState[39]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[39]?.images[2]?.url)} alt="" />
                </div></Link>
                <Link to={`/collections/${collectionState[41]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[41]?.images[2]?.url)} alt="" />
                </div></Link><Link to={`/collections/${collectionState[28]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[28]?.images[2]?.url)} alt="" />
                </div></Link><Link to={`/collections/${collectionState[30]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[30]?.images[2]?.url)} alt="" />
                </div></Link>
            </div>
        </div>
    )
}

export default Women
